import React, { useRef, useState } from "react";
import ChatBox from "../assets/svgs/chatbox.svg";
import appstoreButton from "../assets/svgs/appstoreButton.svg";
import playstoreButton from "../assets/svgs/playstoreButton.svg";
import Illustration from "../assets/svgs/illustration.svg";
import Human from "../assets/svgs/human.svg";
import Star from "../assets/svgs/star.svg";
import ArrowDown from "../assets/svgs/arrowDown.svg";
import HumanIllustration from "../assets/svgs/HumanIllustration.svg";
import HashTag from "../assets/svgs/hash.svg";
import MessageGif from "../assets/video/messageGif.gif";
import TagGif from "../assets/video/tagGif.gif";
import {
  AiOutlineArrowRight,
  AiOutlineCheck,
  AiOutlineInstagram,
  AiOutlineArrowDown,
  AiOutlineDoubleRight,
} from "react-icons/ai";
import { CiTwitter } from "react-icons/ci";
import { FiArrowDown, FiFacebook } from "react-icons/fi";
import { fetchUsersSpecificFeed } from "../ApiManager";

const Hero = () => {
  const [checked, setChecked] = useState(true);

  const [email, setEmail] = useState("");
  const myRef = useRef(null);
  const inputRef = useRef(null);

  const [successStatus, setSuccessStatus] = useState(0);

  function handleInputChange(event) {
    setEmail(event.target.value);
  }

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(validRegex)) {
      fetchUsersSpecificFeed(email, checked).then((res) => {
        if (res.error === false) {
          setSuccessStatus(1);
        } else {
          alert(res.message);
        }
      });
    } else {
      alert("Please enter valid email address");
    }
  };

  const executeScroll = () => {
    const element = document.getElementById("section-3");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
    inputRef.current.focus();
  };
  const handleChange = () => {
    setChecked(!checked);
  };
  return (
    <div className="">
      <div className=" flex-col flex  bg-[#940DFF] rounded-b-[40px] ">
        <div className="flex flex-row relative">
          {/* <div className="flex self-end absolute  h-[60%] md:h-[90%] w-[50%] bg-white lg:ml-[13%] md:ml-[5%] z-0 overflow">
            <img src={Human} className="object-cover" />
          </div> */}
          <div className=" w-[100%] flex flex-col items-center justify-center pt-[70px] ">
            <h1 className="text-[#fff] text-4xl md:text-5xl lg:text-6xl text-center  font-medium font-roboto_black 	">
              A Community Where <br /> Everyone Belongs
            </h1>
            <h5 className="font-light text-sm mt-2 md:text-[16px] mt-6 w-[55%] md:w-[40%] text-center font-lato_regular text-white">
              An app where you answer, learn, and feed your curiosity!
            </h5>
            <div className="flex-row flex items-center">
              <a
                href="https://apps.apple.com/us/app/recozilla/id1672132208"
                className="w-[140px] md:w-[180px] my-8 rounded-[100px] mx-4"
              >
                <img src={appstoreButton} className="mr-2" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.recozilla.recozillaapp"
                className="w-[140px] md:w-[180px] my-8 rounded-[100px]"
              >
                <img src={playstoreButton} className="" />
              </a>
            </div>

            {/* w-[100px] md:w-[150px] my-8 rounded-[100px] mx-4 justify-center hover:cursor-pointer */}
            {/* <a
              href="https://www.instagram.com/joinrecozilla/"
              className="hover:cursor-pointer"
            ></a> */}
            {/* <div
              onClick={() => executeScroll()}
              className=" bg-black h-[50px] items-center w-[200px] md:w-[250px] my-8 rounded-[100px] mx-4  flex  justify-center hover:cursor-pointer"
            >
              <h1 className="text-white text-center text-md md:text-md font-lato_medium ">
                Get Early Access
              </h1>
            </div> */}

            <div className="flex flex-row  pb-3 ">
              <a
                href="https://twitter.com/JoinRecozilla"
                className="hover:cursor-pointer"
              >
                <CiTwitter
                  size={35}
                  color="#BFFECD"
                  className="hover:cursor-pointer"
                  strokeWidth={0.8}
                />
              </a>

              <a
                href="https://www.instagram.com/joinrecozilla/"
                className="hover:cursor-pointer"
              >
                <AiOutlineInstagram
                  size={35}
                  color="#BFFECD"
                  className="hover:cursor-pointer ml-6"
                  strokeWidth={1}
                />
              </a>
            </div>
            <h6 className="text-white text-sm mb-6">#joinrecozilla</h6>
            <img
              src={Star}
              className="w-[15%]  self-end absolute bottom-[10%] right-[4%]"
            />
            <img
              src={Star}
              className="w-[15%]  self-end absolute top-[5%] left-[20%]"
            />
            {/* <img
              onClick={() => executeScroll()}
              src={ArrowDown}
              className="w-6 my-6 animate-bounce"
            /> */}
          </div>
        </div>
        {/* <img
          src={Human}
          className="absolute left-0 overflow-hidden h-[130px] w-[200px]"
        />
        <h1 className="text-[#fff] text-2xl text-center uppercase font-medium md:text-4xl font-rock_bold">
          A Community Where Everyone Belongs
        </h1>
        <h1 className="text-center text-lg md:text-2xl font-extralight text-white my-2 max-w-[80%] font-lato_thin">
          A place to learn from knowledge and experiences of others and share
          yours.
        </h1>

        <div className="bg-[#000000] py-3 w-[250px] rounded-[40px] mx-4 mt-2 flex items-center justify-center hover:cursor-pointer">
          <h1 className="text-white text-center">Get Early Access</h1>
        </div> */}
      </div>

      {/* <div className="pt-10  flex overflow-hidden bg-[#940DFF]">
        <img src={Illustration} />
      </div> */}

      {/* Second Section */}

      <div className="py-[35%] px-1 h-64 flex lg:px-[10%]  md:py-[18%] w-full justify-around  md:justify-around items-center bg-[#fff] ">
        <div className=" w-[60%] md:w-[40%]">
          <h1 className="text-[#940DFF] text-2xl font-medium md:text-4xl font-roboto_black">
            Explore Topics Of Your Interest
          </h1>
          <h2 className="font-light text-sm mt-2 md:text-xl  font-lato_regular">
            Whether you are curious about health, life, relationships, career,
            movies, music or anything else, you can ask questions, get answers,
            and learn from fellow learners on our app.{" "}
          </h2>
        </div>
        <img src={TagGif} className="w-[120px] md:w-[300px] object-contain" />
      </div>

      {/* Third Section */}
      {/* {successStatus ? (
        <div className=" px-1 flex w-full justify-between  md:justify-around items-center relative bg-[#EC2872] ">
          <div className="h-64 justify-center items-center flex">
            <h1 className="text-white text-2xl  md:text-4xl font-roboto_black text-center">
              Yayyy! Thank you for signing up. We will let you know when you
              have access to our app.
            </h1>
            <img
              src={Star}
              className="w-[10%]  self-end absolute bottom-[10%] right-[4%]"
            />
            <img
              src={Star}
              className="w-[5%]  self-end absolute top-[5%] left-[20%]"
            />
          </div>
        </div>
      ) : (
        <div className=" px-1 flex w-full justify-between  md:justify-around items-center relative bg-[#EC2872] ">
          <div className="overflow-hidden">
            <img
              src={HumanIllustration}
              className="w-[20%] ml-[4%]  md:w-[200px] sm:w-[130px] md:ml-[0%]  lg:ml-[11%]  absolute bottom-0"
            />
          </div>
          <div className=" w-[75%] md:w-[60%] lg:w-[40%] p-10 md:p-20  self-center  lg:mr-[10%] md:mr-[0]">
            <h1 className="text-center text-sm text-white font-roboto_black  mb-2 md:text-2xl lg:text-3xl md:pb-[10px]">
              Sign up for Early Access
            </h1>
            <div className="flex  w-full items-center bg-black py-2 md:py-4 px-4 rounded-full">
              <form className="w-full">
                <input
                  ref={inputRef}
                  type="text"
                  // onKeyDown={handleKeyDown}
                  value={email}
                  onChange={handleInputChange}
                  placeholder="Enter your email id"
                  className="w-full outline-none bg-black text-sm text-white outline-0 font-lato_regular pr-[20px]"
                />
              </form>
              <AiOutlineArrowRight
                onClick={handleEmailSubmit}
                className="cursor-pointer"
                color="#fff"
                size={20}
              />
            </div>
            <div className="flex pt-2 items-center ml-[18px]">
              <div
                onClick={handleChange}
                className="w-4 h-4 mr-2 border rounded-sm border-2 flex justify-center items-center cursor-pointe"
              >
                {checked ? <AiOutlineCheck color="#fff" size={20} /> : null}
              </div>
              <p className="ml-1 text-white text-[10px] md:text-[14px]">
                Signup for the newsletter
              </p>
            </div>
          </div>
        </div>
      )} */}
      {/* Fourth Section */}
      <div className="py-10  h-60 lg:px-[10%] md:py-[18%] flex md:px-[5%] w-full justify-around  md:justify-around items-center bg-[#fff] ">
        <img
          src={MessageGif}
          className="w-[120px] md:w-[300px] object-contain"
        />
        <div className=" w-[60%] md:w-[40%]">
          <h1 className="text-[#EC2872] text-2xl font-medium md:text-4xl font-roboto_black">
            Connect With Your Tribe
          </h1>
          <h2 className="font-light text-sm mt-2 md:text-xl  font-lato_regular">
            Follow, message, and interact with people who have similar interests
            and questions on our app.{" "}
          </h2>
        </div>
      </div>

      {/* <div className="py-10  h-64  md:h-2/3 flex  w-full justify-around  md:justify-around  bg-[#fff] ">
        <img
          src={MessageGif}
          className="w-[160px] md:w-[350px] object-contain"
        />
        <div className=" w-[60%] md:w-[40%]">
          <h1 className="text-[#EC2872] text-xl uppercase font-medium md:text-4xl font-rock_bold">
            Two Way Communication
          </h1>
          <h2 className="font-light text-sm mt-2 md:text-xl  font-lato_regular">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.{" "}
          </h2>
        </div>
      </div> */}

      {/* <div className="py-10 h-64 px-5 md:px-40 flex justify-around bg-white">
        <img src={MessageGif} className="w-[150px] mr-[50px] md:w-[200px] " />
        <div>
          <h1 className="text-[#EC2872] text-xl uppercase font-medium md:text-4xl font-rock_bold">
            Two Way Communication
          </h1>
          <h2 className="font-light text-sm mt-2 md:text-xl  font-lato_regular">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.{" "}
          </h2>
        </div>
      </div> */}
    </div>
  );
};

export default Hero;

{
  /* <>
            
          </> */
}
