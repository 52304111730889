import React, { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import email from "../assets/svgs/at.svg";
import location from "../assets/svgs/anchor.svg";
import phone from "../assets/svgs/mobile.svg";
import ContactIllustration from "../assets/svgs/contactIllustration.svg";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    message: "",
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Navbar />
      <div className="px-5 pt-10 lg:px-[20%] ">
        <h1 className="font-lato_bold text-[30px] md:text-3xl mb-2 text-[#940DFF]">
          Let’s Keep in Touch
        </h1>
        <p className="my-2 font-lato_regular text-md">
          Please reach out to us for any questions or suggestions at following
          contact details. Alternatively, you can send us your query using the
          form on this page.
        </p>
        <div className="flex items-center py-[10px]">
          <img
            alt=""
            src={email}
            className="mr-5"
            style={{ height: "25px", width: "25px" }}
          />
          <p className="text-[#5865F2] text-md">support@recozilla.com</p>
        </div>
        <div className="flex items-center py-[10px]">
          <img
            src={location}
            className="mr-5"
            style={{ height: "25px", width: "25px" }}
            alt=""
          />
          <p className="text-[#5865F2] text-md">
            Recozilla, WeWork Rajapushpa Summit, Gachibowli, Hyderabad - 500032,
            India
          </p>
        </div>
        <div className="flex items-center py-[10px]">
          <img
            src={phone}
            className="mr-5"
            style={{ height: "25px", width: "25px" }}
            alt=""
          />
          <p className="text-[#5865F2] text-md">+91 9052511531</p>
        </div>
        <dib className="flex justify-end align-bottom items-baseline mt-[5%]">
          <img src={ContactIllustration} className="align-baseline" />
        </dib>
      </div>

      {/* <div className=" py-10 lg:px-[10%]">
        <div className="px-5">
          <h1 className="font-rock_bold text-[30px] md:text-4xl mb-2 text-[#940DFF]">
            Let’s Keep in Touch
          </h1>
          <p className="my-2 font-lato_regular text-lg md:text-xl">
            Please reach out to us for any questions or suggestions at following
            contact details. Alternatively, you can send us your query using the
            form on this page.
          </p>
        </div>

        <div className="mt-[30px] px-[50px]">
          <div className="flex items-center py-[10px]">
            <img
              alt=""
              src={email}
              className="mr-5"
              style={{ height: "25px", width: "25px" }}
            />
            <p className="text-[#5865F2] text-lg">support@recozilla.com</p>
          </div>

          <div className="flex items-center py-[10px]">
            <img
              src={location}
              className="mr-5"
              style={{ height: "25px", width: "25px" }}
              alt=""
            />
            <p className="text-[#5865F2] text-lg">
              Recozilla, WeWork Rajapushpa Summit, Gachibowli, Hyderabad -
              500032, India
            </p>
          </div>

          <div className="flex items-center py-[10px]">
            <img
              src={phone}
              className="mr-5"
              style={{ height: "25px", width: "25px" }}
              alt=""
            />
            <p className="text-[#5865F2] text-lg">+91 9052511531</p>
          </div>
        </div>

        <div className=" py-[30px] bg-[#FFFFFFF2] mx-[40px]">
          <form action="" className="md:w-[600px]">
            <div className="flex flex-col mb-5">
              <label
                htmlFor="username"
                className="mb-2 font-lato_regular text-lg"
              >
                Your Name
              </label>
              <input
                value={formData.username}
                onChange={handleInput}
                autoComplete="off"
                className="border-[1.5px] border-[#A2A7AD] rounded-[8px] px-4 py-2"
                type="text"
                name="username"
                id="username"
                placeholder="Your Name"
              />
            </div>
            <div className="flex flex-col mb-5">
              <label
                className="mb-2 font-lato_regular text-lg"
                htmlFor="username"
              >
                Your Email
              </label>
              <input
                value={formData.email}
                onChange={handleInput}
                autoComplete="off"
                className="border-[1.5px] border-[#A2A7AD] rounded-[8px] px-4 py-2"
                type="text"
                name="email"
                id="email"
                placeholder="Your Email"
              />
            </div>
            <div className="flex flex-col mb-5">
              <label
                className="mb-2 font-lato_regular text-lg"
                htmlFor="username"
              >
                Your Message
              </label>
              <textarea
                name="message"
                id="message"
                value={formData.message}
                onChange={handleInput}
                className="border-[1.5px] border-[#A2A7AD] rounded-[8px] px-4 py-2 h-[200px]"
                placeholder="Type here..."
              ></textarea>

              <div
                onClick={handleSubmit}
                className="py-3"
                style={{
                  backgroundColor: "black",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  borderRadius: 10,
                  marginTop: 20,
                }}
              >
                <h1 className="text-white font-lato_regular text-[18px]">
                  Send
                </h1>
              </div>
            </div>
          </form>
        </div>
      </div> */}
      <Footer />
    </>
  );
};

export default ContactUs;
